.metric {
  .metric-value, .metric-name {
    display: block;
  }
}

$indicatorColor: #D11F9D;

.indicator-wrapper {
  .card {
    border-top-color: $indicatorColor;
  }

  .metric-name {
    font-size: 1.5rem;
  }

  .metric-value {
    color: $indicatorColor;
    font-size: 5rem;
  }

  .metric {
    padding: 1rem 2rem;
  }
}