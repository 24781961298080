$zIndex: (
        base: 1,
        navModules: 1000,
        header : 5000,
        loading: 10000
);

.z-base {
  z-index: map-get($zIndex, base);
}
