@import "~react-theme-components/assets/css/main.css";
@import "variables";
@import "bootstrap";
@import "zindex";
@import "colors";
@import "mixins";
@import "sz";
@import "forms";

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  background: var(--body-background);
}

html, body, #root {
  height: 100%;
}

#root {
  padding-top: 60px;
  height: 100%;
}

main {
  background-color: var(--main-bg);
}

a {
  color: var(--text-color);
  &:hover {
    color: var(--text-color-hover);
    text-decoration:none;
  }
}

.box-shadow {
  background: var(--white);
  box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05), 0 2px 4px 0 rgba(17, 95, 251, 0.1);
  padding: 8px 16px;
}

.box {
  padding: 24px;
  border-top: 4px solid var(--primary);
  background-color: var(--box-bg);
  box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05), 0 2px 4px 0 rgba(17, 95, 251, 0.1);

  &--no-padding {
    @extend .box;
    padding: 0;
  }

  &.cat-portal {
    border-color: var(--module-portal)
  }

  &.cat-requests {
    border-color: var(--module-requests)
  }
}

.color {
  &-regular {
    @include color(var(--text-color-regular));
  }
}

.legend {
  list-style: none;

  &-inline {
    @extend .legend;
    padding: 0;

    li {
      display: inline-block;
      padding-right: 2rem;
    }
  }
}

.icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 1.4rem;

  &-big {
    @extend .icon;
    width: 24px;
    height: 24px;
    font-size: 2.4rem;
    line-height: 2.4rem;
  }

  &-huge {
    @extend .icon;
    width: 48px;
    height: 48px;
    font-size: 4.8rem;
    line-height: 4.8rem;
  }
}

.intervention-icon {
  @each $key, $value in $status-list {
    &__#{$value} {
      fill: var(--status-#{$value});
    }
  }
}

.bg-dark {
  @include background-color(var(--background-dark));
}

.no-scroll {
  overflow-y: hidden;
}

.extranet-alerts {
  .alert-text {
      font-size: 1.2rem;

      p {
          margin: 0;
      }
  }

  i {
      font-size: 2.4rem;
  }
}