@import '~leaflet/dist/leaflet.css';
@import '~react-leaflet-markercluster/dist/styles.min.css';

.leaflet-container {
  width: auto;
  height: 100%;
  min-height: 120px;
}

.marker-cluster-custom {
  background: var(--marker-cluster-bg);
  border: 2px solid var(--marker-cluster-border);
  border-radius: 50%;
  color: var(--marker-cluster-color);
  line-height: 26px;
  text-align: center;

  &__ongoing {
    @extend .marker-cluster-custom;
    background: var(--status-ongoing);
  }
}