:root {
  --extranet-blue1: #F4F8FF;
  --extranet-blue2: #3D466C;
  --extranet-blue3: #2E88FF;
  --extranet-blue4: #00A4B6;
  --extranet-blue5: #030F40;
  --extranet-orange1: #FF8363;
  --extranet-purple1: #C65BAB;
  --extranet-red1: #EB4A65;
  --extranet-red2: #800E21;
  --extranet-yellow1: #DEB861;
  --extranet-grey1: #F8F9FA;
  --extranet-grey2: #E9ECEF;
  --extranet-grey3: #6C757D;
  --extranet-grey4: #D7D8DF;
  --extranet-grey5: #D5D6DE;
  --extranet-grey6: #ABAFBF;

  --main-bg: var(--extranet-blue1);
  --text-color: var(--extranet-blue5);
  --text-color-hover: var(--extranet-blue3);
  --text-color-regular: var(--extranet-blue2);
  --background-dark: var(--extranet-grey5);

  /* Nav Modules */
  --nav-mod-bg: var(--extranet-grey1);
  --nav-mod-header-bg: var(--extranet-blue4);
  --nav-mod-header-color: var(--white);
  --nav-mod-user-caption-color: var(--extranet-grey3);
  --nav-mod-footer-bg: var(--extranet-grey2);
  --nav-mod-footer-color: var(--extranet-grey3);
  --nav-mod-link: var(--extranet-blue2);
  --nav-mod-link-active: var(--extranet-blue5);

  /* Box */
  --box-bg: var(--white);
  --box-border-default: var(--primary);
  --box-border-light: var(--extranet-grey4);

  /* Modules */
  --module-default-color: var(--white);
  --module-default: var(--extranet-blue4);
  --module-requests: var(--extranet-purple1);
  --module-documents: var(--extranet-blue3);
  --module-indicators: var(--extranet-red1);
  --module-portal: var(--extranet-orange1);
  --module-urbanism: var(--extranet-red2);
  --module-requester: var(--extranet-yellow1);

  /* Marker clusters */
  --marker-cluster-bg: var(--status-planned);
  --marker-cluster-border: var(--white);
  --marker-cluster-color: var(--white);

  /* Status */
  --status-draft: #B3B3B3;
  --status-to-qualify: #DEB861;
  --status-to-specify: #EB4A65;
  --status-to-plan: #FF8363;
  --status-abandonned: #030F40;
  --status-planned: #E6007E;
  --status-ongoing: #2E88FF;
  --status-premise-done: #5ADEC0;
  --status-done: #5ADEC0;
  --status-closed-rejected: #C65BAB;
  --status-closed: #030F40;

  /* Text & icons colors */
  --text-color-light: var(--extranet-grey3);
  --icon-primary-color: var(--extranet-blue5);

  /* Switches */
  --switch-bg: var(--extranet-grey5);
  --switch-button: var(--white);
  --switch-checked-button: var(--extranet-blue5);

  /* Notifications */
  --notifications-header-color: var(--extranet-blue2);
  --notifications-header-caption: var(--extranet-grey3);
  --notifications-collapse-caption: var(--extranet-grey3);
}
