@import "src/mixins";

.user-modal {
  margin-top: 50px;

  .sz-modal__header {
    display: none;
  }

  .nav {
    &.nav-tabs {
      display: flex;
      justify-content: center;

      .nav-link {
        padding: 14px;
        font-size: 1.4rem;
        color: var(--nav-mod-link);
        font-weight: 500;

        &.active {
          color: var(--nav-mod-link-active);
          background: none;
        }
      }
    }
  }

  .caption {
    @include color(var(--text-color-light));
  }

  .module-description {
    padding: 12px 5px;
  }

  .icon-big {
    @include color(var(--text-color));
  }
}

@media screen and (min-width: 992px) {
  .user-modal {
    .modal-dialog {
      width: 75%;
    }

    .section-border-left {
      border-left: 2px var(--box-border-light) solid;
    }

    .section-border-right {
      border-right: 2px var(--box-border-light) solid;
    }

    .module-description {
      padding: 24px 25px;

      .icon-huge {
        width: 100px;
        height: 100px;
      }
    }

    .module-icon {
      height: 34px;
    }
  }
}
