@import "../../mixins";

.notifications {
  h2 {
    @include color(var(--notifications-header-color));
  }

  .caption {
    @include color(var(--notifications-header-caption));
  }

  .more {
    font-size: 1.4rem;

    .sz-icon-line {
      width: 24px;
      height: 24px;
      font-size: 2.4rem;
      line-height: 2.4rem;
    }
  }
}
