.metric {
  .metric-value, .metric-name {
    display: block;
  }
}

$urbaColor: #800E21;

.urba-wrapper {
  .card {
    border-top-color: $urbaColor;
  }

  .metric-name {
    font-size: 1.5rem;
  }

  .metric-value {
    color: $urbaColor;
    font-size: 5rem;
  }

  .metric {
    padding: 1rem 2rem;
  }
}