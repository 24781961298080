@import "mixins";

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.6rem;
}

.big {
  line-height: 1.1;
}

.small {
  font-size: 85%;
}

.legend {
  span.d-block {
    display: inline-block !important;
  }
}

.sz-button-group {
  &__btn-choose {
    height: 36px;
    border: 1px solid var(--extranet-grey6)!important;

    &.active {
      @include background-color(var(--background-dark));
    }
  }

  > {
    .sz-button-group__btn-choose:not(:first-child) {
      margin-left: 6px;
    }
  }
}

.sz-left-border {
  &.cat-documents::after {
    background-color: var(--module-documents);
  }
  &.cat-indicators::after {
    background-color: var(--module-indicators);
  }
  &.cat-portal::after {
    background-color: var(--module-portal);
  }
  &.cat-requests::after {
    background-color: var(--module-requests);
  }
}

.modal-dialog {
  margin: 0 auto;
}

@include media-breakpoint-up(md) {
  .modal-dialog {
    margin: 1.75rem auto;
  }
}

@include media-breakpoint-down(md) {
  .modal-content {
    border: 0;
  }
}
