@import "src/mixins";
@import "src/zindex";

.nav-modules {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 6px;
  z-index: map-get($zIndex, navModules);
  width: 264px!important;
  background-color: var(--nav-mod-bg);

  &::before {
    position: absolute;
    content: "";
    left: -8px;
    bottom: 58px;
    width: 8px;
    height: 16px;
    border-color: transparent;
    border-style: solid;
    border-width: 8px 8px 8px 0;
    border-right-color: var(--nav-mod-bg);
  }

  .icon {
    font-size: 2.4rem;
    position: relative;
    top: 6px;
  }

  .small {
    font-size: 1.2rem;
  }

  header {
    background-color: var(--nav-mod-header-bg);
    h1, span {
      @include color(var(--nav-mod-header-color));
    }
  }

  footer {
    color: var(--nav-mod-footer-color);
    background-color: var(--nav-mod-footer-bg);
  }

  .user-block {
      padding-left: 10px;
      span {
        @include color(var(--nav-mod-user-caption-color));
      }
  }

  .modules-block {
    border-top: 2px solid var(--extranet-grey2)
  }
}

.nav-modules-overlay {
  content: '';
  position: absolute;
  z-index: map-get($zIndex, navModules);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.6);
}