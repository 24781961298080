@import "src/mixins";

.main-wrapper {
  height: 100%;
  overflow-y: hidden;

  main {
    height: 100%;
    overflow-y: hidden;
  }
}

.sz-aside {
  &__item {
    padding-left: 18px;

    &.active {
      @include background(var(--nav-item-bg-active));
      @include color(var(--nav-item-text-active));
    }
  }

  > nav {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .sz-aside:not(.open), .sz-aside.sz-aside-exit-done {
    width: 60px;
  }
}
