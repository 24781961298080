@import "src/mixins";

$modules: (
        documents: --module-documents,
        indicators: --module-indicators,
        portal: --module-portal,
        requester: --module-requester,
        requests: --module-requests,
        urbanism: --module-urbanism
);

.module-title {
  position: relative;
  width: 60px;
  height: 34px;
  background-color: var(--module-default);

  &:after {
    content: '°';
    position: absolute;
    right: -5px;
    top: 10px;
    color: var(--module-default-color);
    font-size: 2.2rem;
    font-family: "DINPro-Bold";
  }

  p {
    @include color(var(--module-default-color));
    margin: 0;
    font-size: 2.2rem;
    line-height: 34px;
    text-align: center !important;
  }

  @each $module, $color in $modules {
    &__#{$module} {
      @extend .module-title;
      background-color: var(#{$color});
    }
  }
}

.sz-aside {
  .module-title {
    width: 100%;
  }
}
